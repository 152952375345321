import React from "react";
import { FaSignOutAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const NavigationBar = () => {
  const navigate = useNavigate();

  function signOut() {
    localStorage.clear();
    navigate("/");
  }

  return (
    <nav className="bg-blue-500 text-white">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-20">
          <div className="flex items-center">
            <img
              className="h-8 w-auto sm:h-10"
              src={require("./logo.png")}
              alt="Logo"
            />
            <span className="ml-2 font-bold">PSG College of Technology</span>
          </div>
          <div className="flex items-center">
            <div className="flex items-center">
              <p onClick={signOut} className="flex items-center">
                <FaSignOutAlt className="mr-1 text-xl" />
              </p>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavigationBar;
