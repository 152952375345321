import axios from "axios";

// export const BASE_URL = "http://localhost:8000";
export const BASE_URL = "";

export const axiosInstance = axios.create({
  baseURL: BASE_URL + "/api",
  timeout: 100000,
  headers: {
    "Content-Type": "multipart/form-data",
    accept: "application/json",
  },
});
