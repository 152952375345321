import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../components/api/index.js";
import Login from "./auth/Login.jsx";
import NavigationBar from "../components/Header/Navigationbar";
import Card from "./Card";
import "./PollResults.css";

const NUM_QUESTIONS = 7;

const questions = [
  "Support for NBA preparation (SAR files)",
  "Support for NAAC preparation",
  "Support for NIRF preparation",
  "Support for preparing Timetable",
  "Involvement in development of laboratories and making it available for students.",
  "Contribution to department association",
  "Smooth and cordial interaction with other colleagues in the department and in the campus",
];

const PollResults = () => {
  const [seniorRecommendations, setSeniorRecommendations] = useState([]);
  const [midRecommendations, setMidRecommendations] = useState([]);
  const [returnState, setReturnState] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const userInfo = localStorage.getItem("user");
    const result = JSON.parse(userInfo);

    axiosInstance
      .get("/check-deadline", {
        headers: {
          Authorization: `${token}`,
        },
        params: {
          role: "HOD",
        },
      })
      .then((response) => {
        if (response["data"] === true) {
          axiosInstance
            .get("/nominees", {
              headers: {
                Authorization: `${token}`,
              },
              params: {
                department: result.Deptname,
              },
            })
            .then((response) => {
              setSeniorRecommendations(response.data.senior);
              setMidRecommendations(response.data.mid);
            });
        } else {
          alert("Best Mentor Award nominations closed");
          localStorage.clear();
          setReturnState(false);
        }
      });
  }, []);

  const [ratings, setRatings] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [profile, setProfile] = useState(null);
  const [supportingDocx1, setSupportingDocx1] = useState(null);
  const [supportingDocx2, setSupportingDocx2] = useState(null);
  const [supportingDocx3, setSupportingDocx3] = useState(null);
  const [supportingDocx4, setSupportingDocx4] = useState(null);

  const [selectedSeniorNominee, setSelectedSeniorNominee] = useState("");
  const [selectedMidNominee, setSelectedMidNominee] = useState("");
  const [nomineeId, setNomineeId] = useState("");

  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  const navigate = useNavigate();
  const viewNomination = (ratings, level) => {
    navigate("/view-nomination", {
      state: { ratings: JSON.parse(ratings), category: level },
    });
  };

  const reset = () => {
    window.location.reload();
    window.scrollTo(0, 0);
  };

  const handleRecommendationChange = (level, nominee, id) => {
    setNomineeId(id);
    if (level === "senior") {
      const updatedSeniorRecommendations = seniorRecommendations.map((rec) => ({
        ...rec,
        recommended: rec.nominee === nominee ? true : false,
      }));
      setSeniorRecommendations(updatedSeniorRecommendations);
      setSelectedSeniorNominee(nominee);
      setSelectedMidNominee("");
      setMidRecommendations((midRecs) =>
        midRecs.map((rec) => ({
          ...rec,
          recommended: false,
        }))
      );
    } else if (level === "mid") {
      const updatedMidRecommendations = midRecommendations.map((rec) => ({
        ...rec,
        recommended: rec.nominee === nominee ? true : false,
      }));
      setMidRecommendations(updatedMidRecommendations);
      setSelectedMidNominee(nominee);
      setSelectedSeniorNominee("");
      setSeniorRecommendations((seniorRecs) =>
        seniorRecs.map((rec) => ({
          ...rec,
          recommended: false,
        }))
      );
    }
    setRatings([0, 0, 0, 0, 0, 0, 0]);
    setProfile(null);
    setSupportingDocx1(null);
    setSupportingDocx2(null);
    setSupportingDocx3(null);
    setSupportingDocx4(null);
  };

  const handleRatingChanges = (index, value) => {
    const newRatings = [...ratings];
    newRatings[index] = value;
    setRatings(newRatings);
  };

  const renderStarRating = (index) => {
    const stars = [];
    for (let i = 1; i <= 6; i++) {
      const starClass =
        i <= ratings[index] ? "star star-filled" : "star star-empty";
      stars.push(
        <span
          key={i}
          className={starClass}
          onClick={() => handleRatingChanges(index, i)}
        ></span>
      );
    }
    return stars;
  };

  const getRatingLabel = (rating) => {
    switch (rating) {
      case 1:
        return "NA";
      case 2:
        return "Poor";
      case 3:
        return "Satisfactory";
      case 4:
        return "Good";
      case 5:
        return "Very Good";
      case 6:
        return "Excellent";
      default:
        return "";
    }
  };

  const handleProfileFileChange = (level, nominee, file) => {
    if (level === "senior") {
      const updatedRecommendations = seniorRecommendations.map((rec) => ({
        ...rec,
        profileFile: rec.nominee === nominee ? file : null,
      }));
      setSeniorRecommendations(updatedRecommendations);
    } else if (level === "mid") {
      const updatedRecommendations = midRecommendations.map((rec) => ({
        ...rec,
        profileFile: rec.nominee === nominee ? file : null,
      }));
      setMidRecommendations(updatedRecommendations);
    }
    if (file) setProfile(file);
  };

  const handleSupportingDocx1Change = (level, nominee, file) => {
    if (level === "senior") {
      const updatedRecommendations = seniorRecommendations.map((rec) => ({
        ...rec,
        supportingDoc: rec.nominee === nominee ? file : null,
      }));
      setSeniorRecommendations(updatedRecommendations);
    } else if (level === "mid") {
      const updatedRecommendations = midRecommendations.map((rec) => ({
        ...rec,
        supportingDoc: rec.nominee === nominee ? file : null,
      }));
      setMidRecommendations(updatedRecommendations);
    }
    if (file) setSupportingDocx1(file);
  };

  const handleSupportingDocx2Change = (level, nominee, file) => {
    if (level === "senior") {
      const updatedRecommendations = seniorRecommendations.map((rec) => ({
        ...rec,
        supportingDoc: rec.nominee === nominee ? file : null,
      }));
      setSeniorRecommendations(updatedRecommendations);
    } else if (level === "mid") {
      const updatedRecommendations = midRecommendations.map((rec) => ({
        ...rec,
        supportingDoc: rec.nominee === nominee ? file : null,
      }));
      setMidRecommendations(updatedRecommendations);
    }
    if (file) setSupportingDocx2(file);
  };

  const handleSupportingDocx3Change = (level, nominee, file) => {
    if (level === "senior") {
      const updatedRecommendations = seniorRecommendations.map((rec) => ({
        ...rec,
        supportingDoc: rec.nominee === nominee ? file : null,
      }));
      setSeniorRecommendations(updatedRecommendations);
    } else if (level === "mid") {
      const updatedRecommendations = midRecommendations.map((rec) => ({
        ...rec,
        supportingDoc: rec.nominee === nominee ? file : null,
      }));
      setMidRecommendations(updatedRecommendations);
    }
    if (file) setSupportingDocx3(file);
  };

  const handleSupportingDocx4Change = (level, nominee, file) => {
    if (level === "senior") {
      const updatedRecommendations = seniorRecommendations.map((rec) => ({
        ...rec,
        supportingDoc: rec.nominee === nominee ? file : null,
      }));
      setSeniorRecommendations(updatedRecommendations);
    } else if (level === "mid") {
      const updatedRecommendations = midRecommendations.map((rec) => ({
        ...rec,
        supportingDoc: rec.nominee === nominee ? file : null,
      }));
      setMidRecommendations(updatedRecommendations);
    }
    if (file) setSupportingDocx4(file);
  };

  const check = (nominators_ratings, category) => {
    let flag = true;
    let constraint = category === "senior" ? 3 : 2;

    for (let each in nominators_ratings) {
      const ratings = JSON.parse(nominators_ratings[each].ratings);
      for (let i = 0; i < ratings.length; i++) {
        if (category === "mid" && (i === 13 || i === 14)) continue;
        if (ratings[i] < constraint) {
          flag = false;
          return flag;
        }
      }
    }
    return flag;
  };

  const handleSubmit = (event) => {
    const token = localStorage.getItem("token");

    event.preventDefault();
    setLoading(true);
    const userInfo = localStorage.getItem("user");
    const result = JSON.parse(userInfo);

    let nominee =
      selectedSeniorNominee === "" ? selectedMidNominee : selectedSeniorNominee;
    let level = selectedSeniorNominee === "" ? "mid" : "senior";

    let count = 0;
    for (let i = 0; i < ratings.length; i++) if (ratings[i] !== 0) count++;

    let flag = false;

    if (count === NUM_QUESTIONS) flag = true;

    if (
      profile === null ||
      supportingDocx1 === null ||
      supportingDocx2 === null ||
      supportingDocx3 === null ||
      supportingDocx4 === null
    )
      flag = false;

    if (!flag) {
      alert("Please rate all questions and upload files!");
    } else {
      const formData = new FormData();
      formData.append("nominator", result.Name);
      formData.append("department", result.Deptname);
      formData.append("nominee", nominee);
      formData.append("nominee_id", nomineeId);
      formData.append("category", level);
      formData.append("profile", profile);
      formData.append("supportingDocx1", supportingDocx1);
      formData.append("supportingDocx2", supportingDocx2);
      formData.append("supportingDocx3", supportingDocx3);
      formData.append("supportingDocx4", supportingDocx4);
      formData.append("ratings", JSON.stringify(ratings));

      axiosInstance
        .post("/recommend", formData, {
          headers: {
            Authorization: `${token}`,
          },
          onUploadProgress: (progressEvent) => {
            setProgress(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          },
        })
        .then((res) => {
          console.log(res);
          alert(
            "Your recommendation has been submitted successfully. Thank you for your valuable time."
          );
          reset();
          navigate("/poll-result");
        })
        .finally(() => setLoading(false));
    }
  };

  const renderRecommendations = (recommendations, level) => {
    return recommendations
      .filter((rec) => check(rec.nominators_ratings, level))
      .map((rec) => (
        <Card
          key={rec.nominee}
          className={`card ${rec.recommended ? "recommended" : ""}`}
        >
          <div>
            <div>
              <div className="flex justify-evenly">
                <h3>{rec.nominee}</h3>
                <label className="flex items-center">
                  <input
                    type="radio"
                    checked={
                      level === "senior"
                        ? rec.nominee === selectedSeniorNominee
                        : rec.nominee === selectedMidNominee
                    }
                    onChange={() =>
                      handleRecommendationChange(level, rec.nominee, rec.id)
                    }
                    className="radio-button"
                  />
                  <span>Recommended</span>
                </label>
              </div>

              <div className="flex justify-center items-center w-full text-center mx-auto">
                {rec.recommended && (
                  <div>
                    <h3 className="mt-4 font-bold">
                      Upload PDF for each question
                    </h3>
                    <div className="mt-3">
                      <h3>Upload Profile (with photo)</h3>
                      <input
                        type="file"
                        accept="application/pdf"
                        onChange={(e) =>
                          handleProfileFileChange(
                            level,
                            rec.nominee,
                            e.target.files[0]
                          )
                        }
                      />
                    </div>
                    <div className="mt-3">
                      <h3>
                        Upload upto a two-page statement detailing why the
                        nominee qualifies for the award.
                      </h3>
                      <input
                        type="file"
                        accept="application/pdf"
                        onChange={(e) =>
                          handleSupportingDocx1Change(
                            level,
                            rec.nominee,
                            e.target.files[0]
                          )
                        }
                      />
                    </div>
                    <div className="mt-3">
                      <h3>
                        Upload a one-page statement by the nominee describing
                        his or her philosophy on mentoring, and how he or she
                        views the role, including comments on approaches to
                        promoting student success.
                      </h3>
                      <input
                        type="file"
                        accept="application/pdf"
                        onChange={(e) =>
                          handleSupportingDocx2Change(
                            level,
                            rec.nominee,
                            e.target.files[0]
                          )
                        }
                      />
                    </div>
                    <div className="mt-3">
                      <h3>
                        Upload a list of all graduate students mentored,
                        including brief descriptions of any notable
                        circumstances of students.
                      </h3>
                      <input
                        type="file"
                        accept="application/pdf"
                        onChange={(e) =>
                          handleSupportingDocx3Change(
                            level,
                            rec.nominee,
                            e.target.files[0]
                          )
                        }
                      />
                    </div>
                    <div className="mt-3">
                      <h3>
                        Upload upto five letters of support from individuals who
                        are being/were mentored by the nominee, each including a
                        summary of the mentee's experience with the nominated
                        faculty member and subsequent career development.
                        Letters from current or former graduate students will
                        carry more weight. Letters should include details and
                        examples of the mentee's experience with the nominated
                        faculty member. Letters should reflect on the mentee's
                        mentoring experience with the nominated faculty member
                        and how the experience enabled them to succeed in their
                        subsequent career. Letters must have one mentee each; a
                        letter with multiple mentees will not be considered.
                        Letters must be signed by the mentees. Letters must be
                        of 1 page.
                      </h3>
                      <input
                        type="file"
                        accept="application/pdf"
                        onChange={(e) =>
                          handleSupportingDocx4Change(
                            level,
                            rec.nominee,
                            e.target.files[0]
                          )
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>

            {rec.recommended && (
              <div className="questions">
                <h3 className="text-center font-bold mt-4">Questions</h3>
                <table className="w-full text-center mt-5 question-table">
                  <colgroup>
                    <col style={{ width: "auto" }} />
                  </colgroup>
                  <tbody>
                    {questions.map((question, index) => (
                      <tr key={index}>
                        <td>
                          <p>{question}</p>
                          <div className="star-rating">
                            {renderStarRating(index)}
                          </div>
                          <div className="selected-rating-label">
                            {getRatingLabel(ratings[index])}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}

            {rec.recommended && (
              <form className="declaration" onSubmit={handleSubmit}>
                <h3 className="text-center font-bold declaration-title">
                  Declaration
                </h3>
                <div>
                  <p className="declaration-content">
                    <input type="checkbox" required /> Based on the norms
                    prescribed for this award, I recommend <b>{rec.nominee}</b>{" "}
                    of my department for the Best Mentor Award in the{" "}
                    <b>{level} level</b> category. The information provided is
                    true to the best of my knowledge.
                  </p>
                  <button
                    className="bg-blue-500 text-white hover:bg-blue-700 text-sm font-semibold py-2 px-4 rounded focus:outline-none"
                    type="submit"
                  >
                    Submit
                  </button>
                  {loading && (
                    <div class="my-6 h-1 w-full bg-neutral-300 rounded-full">
                      <div
                        className="bg-blue-500 p-0.5 text-center text-xs font-medium leading-none text-primary-100"
                        style={{ width: progress + "%" }}
                      ></div>
                    </div>
                  )}
                </div>
              </form>
            )}

            <h3 className="text-center font-bold mt-4">Nominators</h3>

            <table className="w-full text-center mt-5">
              <colgroup>
                <col style={{ width: "auto" }} />
                <col style={{ width: "max-content" }} />
                <col style={{ width: "auto" }} />
              </colgroup>
              {rec.nominators_ratings.map((nominator, index) => (
                <tr key={index}>
                  <td>{nominator.nominator}</td>
                  <td>
                    Total
                    <span
                      className="stars-icon"
                      role="img"
                      aria-label="Star"
                      style={{ color: "gold" }}
                    >
                      &#9733;
                    </span>
                    :
                    <span className="stars-count">
                      {nominator.total_ratings}
                    </span>
                  </td>
                  <td>
                    <button
                      className="view-nomination-btn my-auto"
                      onClick={() => viewNomination(nominator.ratings, level)}
                    >
                      View
                    </button>
                  </td>
                </tr>
              ))}
            </table>
          </div>
        </Card>
      ));
  };

  if (!returnState) return <Login />;

  return (
    <>
      <NavigationBar />
      <h1 className="font-bold text-center mt-8">
        Best Mentor Award Nomination
      </h1>
      <h1 className="text-center mb-8">Poll Results</h1>
      <div className="mb-20">
        <h2 className="text-center font-bold mt-5 mb-5">Senior Category</h2>
        {seniorRecommendations.length === 0 ? (
          <p className="text-center">No nominations found.</p>
        ) : (
          renderRecommendations(seniorRecommendations, "senior")
        )}
        <h2 className="text-center font-bold mt-5 mb-5">Mid Category</h2>
        {midRecommendations.length === 0 ? (
          <p className="text-center">No nominations found.</p>
        ) : (
          renderRecommendations(midRecommendations, "mid")
        )}
      </div>
    </>
  );
};

export default PollResults;
