import React, { useEffect } from "react";
import "./Quiz.css";
import NavigationBar from "../components/Header/Navigationbar";
import { useLocation } from "react-router-dom";
import NotFoundPage from "./NotFoundPage";

const qSet1 = [
  "Passion for teaching and inspiring students towards learning",
  "Dedication to fostering a positive and inclusive classroom environment",
  "Incorporating innovative teaching methods or technologies to enhance student learning outcome",
  "Excellence in professional conduct",
  "Balancing students interaction and discipline",
  "Punctuality to classes",
  "Punctuality in attending the duties of the college ",
  "Ability to foster written and oral communication skills",
  "Ability to identify slow learners and mentor them",
  "Well-read on current developments in Higher education as per the Government policies",
];

const qSet2 = [
  "Excellence in Integrity",
  "Ability to promote a climate of respect, inclusion and collegiality",
  "Contribution to extracurricular activities that enhance the overall student experience",
];

const specialQuestions = [
  "Ability to connect with alumni ",
  "Ability to integrate students into culture of discipline and helping them to network with Alumni and relevant individuals.",
];

const qSet3 = [
  "Ability to foster competencies in students towards critical thinking",
  "Excellence in Scholarship(knowledge  mastery over the subjects)",
  "Ability to foster scholarly rigor in students",
  "	Student support towards getting internship / for their academic excellence",
];

const qSet4 = [
  "Empathy and understanding towards students' personal challenges and providing appropriate support in a professional manner",
  "Positive attitude to continuous learning",
  "Assisting students to explore career options",
  "Assisting students to find postgraduate positions for higher studies",
];

const qSet5 = [
  "Capacity to organize curricular activities",
  "Readiness to Speak for students when necessary, and encourages them to make best use of institutional and professional opportunities.",
  "Ability to organize department category activities",
  "Clarity while communicating with the stake holders",
  "Professionally  deals with parents, guardians and students",
];

const ViewNomination = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (location.state === undefined || location.state === null) {
    return <NotFoundPage />;
  } else {
    const { ratings, category } = location.state;

    const renderStarRating = (index) => {
      const stars = [];
      const rating = ratings[index];

      for (let i = 1; i <= 5; i++) {
        const starClass = i <= rating ? "star star-filled" : "star star-empty";
        stars.push(<span key={i} className={starClass}></span>);
      }

      return stars;
    };

    const getRatingLabel = (rating) => {
      switch (rating) {
        case 1:
          return "Poor";
        case 2:
          return "Satisfactory";
        case 3:
          return "Good";
        case 4:
          return "Very Good";
        case 5:
          return "Excellent";
        default:
          return "";
      }
    };

    return (
      <>
        <NavigationBar />
        <div className="rating-page">
          <div>
            <h1 className="font-bold">Best Mentor Award Nomination</h1>
          </div>

          <p className="font-bold my-8">Self discipline and Pedagogy</p>
          {qSet1.map((question, index) => (
            <div key={index} className="question">
              <p>{question}</p>
              <div className="star-rating">{renderStarRating(index)}</div>
              <div className="selected-rating-label">
                {getRatingLabel(ratings[index])}
              </div>
            </div>
          ))}

          <p className="font-bold my-8">Ability to be with a Team</p>
          {qSet2.map((question, index) => (
            <div key={index} className="question">
              <p>{question}</p>
              <div className="star-rating">{renderStarRating(10 + index)}</div>
              <div className="selected-rating-label">
                {getRatingLabel(ratings[10 + index])}
              </div>
            </div>
          ))}

          {category === "senior" &&
            specialQuestions.map((question, index) => (
              <div key={index} className="question">
                <p>{question}</p>
                <div className="star-rating">
                  {renderStarRating(13 + index)}
                </div>
                <div className="selected-rating-label">
                  {getRatingLabel(ratings[13 + index])}
                </div>
              </div>
            ))}

          <p className="font-bold my-8">
            Student support for scholarly activities
          </p>
          {qSet3.map((question, index) => (
            <div key={index} className="question">
              <p>{question}</p>
              <div className="star-rating">{renderStarRating(15 + index)}</div>
              <div className="selected-rating-label">
                {getRatingLabel(ratings[15 + index])}
              </div>
            </div>
          ))}

          <p className="font-bold my-8">Students' counselling</p>
          {qSet4.map((question, index) => (
            <div key={index} className="question">
              <p>{question}</p>
              <div className="star-rating">{renderStarRating(19 + index)}</div>
              <div className="selected-rating-label">
                {getRatingLabel(ratings[19 + index])}
              </div>
            </div>
          ))}

          <p className="font-bold my-8">Leadership</p>
          {qSet5.map((question, index) => (
            <div key={index} className="question">
              <p>{question}</p>
              <div className="star-rating">{renderStarRating(23 + index)}</div>
              <div className="selected-rating-label">
                {getRatingLabel(ratings[23 + index])}
              </div>
            </div>
          ))}
        </div>
      </>
    );
  }
};

export default ViewNomination;
