import React from "react";

const NotFoundPage = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen">
      <img
        src={require("./404.png")}
        alt="Page Not Found"
        className="w-48 mb-4"
      />
      <p className="text-lg text-gray-600">Oops! Page not found</p>
      <a
        href="/"
        className="mt-4 px-4 py-2 text-sm font-semibold text-white bg-blue-500 rounded-md shadow-md hover:bg-blue-600 transition-colors duration-300"
      >
        Go Back
      </a>
    </div>
  );
};

export default NotFoundPage;
