import React from "react";
import { Route, Routes } from "react-router-dom";

import Login from "../../pages/auth/Login";
import Quiz from "../../pages/Quiz";
import PollResults from "../../pages/PollResults";
import ViewNomination from "../../pages/ViewNomination";
import Result from "../../pages/Result";
import NotFoundPage from "../../pages/NotFoundPage";
import ViewRecommendation from "../../pages/ViewRecommendation";
import AdminDeadlineSelection from "../../pages/AdminDeadlineSelection";
import AdminLanding from "../../pages/AdminLanding";
import ProtectedRoute from "./ProtectedRoute";
import FacultySubmissions from "../../pages/FacultySubmissions";
import HODSubmissions from "../../pages/HODSubmissions";
import AddAdmin from "../../pages/AddAdmin";

const Layout = () => {
  return (
    <div>
      <div>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/quiz"
            element={
              <ProtectedRoute hasRole="FACULTY">
                <Quiz />
              </ProtectedRoute>
            }
          />

          <Route
            path="/poll-result"
            element={
              <ProtectedRoute hasRole="HOD">
                <PollResults />
              </ProtectedRoute>
            }
          />

          <Route
            path="/view-nomination"
            element={
              <ProtectedRoute hasRole="HOD">
                <ViewNomination />
              </ProtectedRoute>
            }
          />

          <Route
            path="/view-recommendation"
            element={
              <ProtectedRoute hasRole="PRINCIPAL">
                <ViewRecommendation />
              </ProtectedRoute>
            }
          />

          <Route
            path="/nomination-result"
            element={
              <ProtectedRoute hasRole="PRINCIPAL">
                <Result />
              </ProtectedRoute>
            }
          />

          <Route
            path="/deadlines"
            element={
              <ProtectedRoute hasRole="ADMIN">
                <AdminDeadlineSelection />
              </ProtectedRoute>
            }
          />

          <Route
            path="/admin-landing"
            element={
              <ProtectedRoute hasRole="ADMIN">
                <AdminLanding />
              </ProtectedRoute>
            }
          />

          <Route
            path="/faculty-submissions"
            element={
              <ProtectedRoute hasRole="ADMIN">
                <FacultySubmissions />
              </ProtectedRoute>
            }
          />

          <Route
            path="/hod-submissions"
            element={
              <ProtectedRoute hasRole="ADMIN">
                <HODSubmissions />
              </ProtectedRoute>
            }
          />

          <Route
            path="/add-admin"
            element={
              <ProtectedRoute hasRole="ADMIN">
                <AddAdmin />
              </ProtectedRoute>
            }
          />

          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </div>
    </div>
  );
};

export default Layout;
