import React, { useState, useEffect } from "react";
import NavigationBar from "../components/Header/Navigationbar";
import { axiosInstance } from "../components/api/index.js";
import Card from "./Card";
import { useNavigate } from "react-router-dom";

const Result = () => {
  const [results, setResults] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("token");

    axiosInstance
      .get("/result", {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((response) => {
        setResults(response.data);
      });
  }, []);

  const navigate = useNavigate();

  const viewNomination = (ratings, level) => {
    navigate("/view-nomination", {
      state: { ratings: JSON.parse(ratings), category: level },
    });
  };

  const viewRecommendation = (ratings) => {
    navigate("/view-recommendation", {
      state: { ratings: JSON.parse(ratings) },
    });
  };

  const handleProfileDownload = (nominee_id) => {
    const token = localStorage.getItem("token");
    axiosInstance
      .get("/profile", {
        headers: {
          Authorization: `${token}`,
        },
        params: {
          id: nominee_id,
        },
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "profile.pdf");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log("Error downloading profile", error);
      });
  };

  const handleDocxDownload = (nominee_id, number) => {
    const token = localStorage.getItem("token");
    axiosInstance
      .get("/docx", {
        headers: {
          Authorization: `${token}`,
        },
        params: {
          id: nominee_id,
          number: number,
        },
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "supporting_document_" + number + ".pdf");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log("Error downloading supporting document", error);
      });
  };

  const generateFinalPDF = () => {
    const token = localStorage.getItem("token");

    axiosInstance
      .get("/final-pdf", { 
        headers: {
          Authorization: `${token}`,
        },
        responseType: "blob" })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "BMA_report.pdf");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log("Error downloading final pdf", error);
      });
  };

  const renderResults = (results) => {
    return results.map((res) => (
      <div>
        <p className="text-center font-bold font-xs mt-5 mb-5">
          {res.department} -{" "}
          {res.category === "senior" ? "Senior Level" : "Mid Level"}
        </p>

        <Card>
          <div>
            <div>
              <h3 className="text-center">{res.nominee}</h3>
            </div>

            <div className="flex justify-center items-center w-full text-center mx-auto">
              <div>
                <div className="mt-3">
                  <h3>View Profile</h3>
                  <button
                    className="bg-gray-400 text-white hover:bg-gray-500 text-sm font-semibold mr-2"
                    onClick={() => handleProfileDownload(res.nominee_id)}
                  >
                    Get
                  </button>
                </div>
                <div className="mt-3">
                  <h3>
                    View a two-page statement detailing why the nominee
                    qualifies for the award
                  </h3>
                  <button
                    className="bg-gray-400 text-white hover:bg-gray-500 text-sm font-semibold ml-2"
                    onClick={() => handleDocxDownload(res.nominee_id, "1")}
                  >
                    Get
                  </button>
                </div>
                <div className="mt-3">
                  <h3>
                    View a one-page statement by the nominee describing his or
                    her philosophy on mentoring, and how he or she views the
                    role, including comments on approaches to promoting student
                    success.
                  </h3>
                  <button
                    className="bg-gray-400 text-white hover:bg-gray-500 text-sm font-semibold ml-2"
                    onClick={() => handleDocxDownload(res.nominee_id, "2")}
                  >
                    Get
                  </button>
                </div>
                <div className="mt-3">
                  <h3>
                    View a list of all graduate students mentored, including
                    brief descriptions of any notable circumstances of students.
                  </h3>
                  <button
                    className="bg-gray-400 text-white hover:bg-gray-500 text-sm font-semibold ml-2"
                    onClick={() => handleDocxDownload(res.nominee_id, "3")}
                  >
                    Get
                  </button>
                </div>
                <div className="mt-3">
                  <h3>
                    View five letters of support from individuals who are
                    being/were mentored by the nominee, each including a summary
                    of the mentee's experience with the nominated faculty member
                    and subsequent career development. Letters from current or
                    former graduate students will carry more weight. Letters
                    should include details and examples of the mentee's
                    experience with the nominated faculty member. Letters should
                    reflect on the mentee's mentoring experience with the
                    nominated faculty member and how the experience enabled them
                    to succeed in their subsequent career. Letters must have one
                    mentee each; a letter with multiple mentees will not be
                    considered. Letters must be signed by the mentees. Letters
                    must be of 1 page.
                  </h3>
                  <button
                    className="bg-gray-400 text-white hover:bg-gray-500 text-sm font-semibold ml-2"
                    onClick={() => handleDocxDownload(res.nominee_id, "4")}
                  >
                    Get
                  </button>
                </div>
              </div>
            </div>

            <h3 className="text-center font-bold mt-4">HOD</h3>

            <table className="w-full text-center mt-5">
              <colgroup>
                <col style={{ width: "auto" }} />
                <col style={{ width: "max-content" }} />
                <col style={{ width: "auto" }} />
              </colgroup>
              <tr>
                <td>{res.nominator}</td>
                <td>
                  Total
                  <span
                    className="stars-icon"
                    role="img"
                    aria-label="Star"
                    style={{ color: "gold" }}
                  >
                    &#9733;
                  </span>
                  :<span className="stars-count">{res.ratingsSum}</span>
                </td>
                <td>
                  <button
                    className="view-nomination-btn my-auto"
                    onClick={() => viewRecommendation(res.ratings)}
                  >
                    View
                  </button>
                </td>
              </tr>
            </table>

            <h3 className="text-center font-bold mt-4">Nominators</h3>

            <table className="w-full text-center mt-5">
              <colgroup>
                <col style={{ width: "auto" }} />
                <col style={{ width: "max-content" }} />
                <col style={{ width: "auto" }} />
              </colgroup>
              {res.nominators_ratings.map((nominator, index) => (
                <tr key={index}>
                  <td>{nominator.nominator}</td>
                  <td>
                    Total
                    <span
                      className="stars-icon"
                      role="img"
                      aria-label="Star"
                      style={{ color: "gold" }}
                    >
                      &#9733;
                    </span>
                    :
                    <span className="stars-count">
                      {nominator.total_ratings}
                    </span>
                  </td>
                  <td>
                    <button
                      className="view-nomination-btn my-auto"
                      onClick={() =>
                        viewNomination(nominator.ratings, res.category)
                      }
                    >
                      View
                    </button>
                  </td>
                </tr>
              ))}
            </table>
          </div>
        </Card>
      </div>
    ));
  };

  return (
    <>
      <NavigationBar />
      <div className="text-center my-8">
        <h1 className="font-bold">Best Mentor Award Nomination</h1>
        <h1 className="text-center mb-2">Poll Results</h1>
        <button
          className="bg-red-400 text-white hover:bg-red-500 font-semibold mb-4"
          onClick={() => generateFinalPDF()}
        >
          Get BMA report
        </button>

        {results.length === 0 ? (
          <p className="text-center">No results.</p>
        ) : (
          renderResults(results)
        )}
      </div>
    </>
  );
};

export default Result;
