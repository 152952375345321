import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { axiosInstance } from "../api";

export default function ProtectedRoute({ children, hasRole }) {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("token");

    axiosInstance
      .get("/get-user", {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((response) => {
        const roles = response.data.roleName;
        setIsAuthorized(roles.includes(hasRole));
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
        setIsAuthorized(false);
        setIsLoading(false);
      });
  }, [hasRole]);

  if (isLoading) {
    return null;
  }

  return isAuthorized ? children : <Navigate to="/" />;
}
