import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../components/api/index.js";
import NavigationBar from "../components/Header/Navigationbar";
import Login from "../pages/auth/Login.jsx";
import "./Quiz.css";

const NUM_QUESTIONS = 28;

const qSet1 = [
  "Passion for teaching and inspiring students towards learning",
  "Dedication to fostering a positive and inclusive classroom environment",
  "Incorporating innovative teaching methods or technologies to enhance student learning outcome",
  "Excellence in professional conduct",
  "Balancing students interaction and discipline",
  "Punctuality to classes",
  "Punctuality in attending the duties of the college ",
  "Ability to foster written and oral communication skills",
  "Ability to identify slow learners and mentor them",
  "Well-read on current developments in Higher education as per the Government policies",
];

const qSet2 = [
  "Excellence in Integrity",
  "Ability to promote a climate of respect, inclusion and collegiality",
  "Contribution to extracurricular activities that enhance the overall student experience",
];

const specialQuestions = [
  "Ability to connect with alumni ",
  "Ability to integrate students into culture of discipline and helping them to network with Alumni and relevant individuals.",
];

const qSet3 = [
  "Ability to foster competencies in students towards critical thinking",
  "Excellence in Scholarship(knowledge  mastery over the subjects)",
  "Ability to foster scholarly rigor in students",
  "	Student support towards getting internship / for their academic excellence",
];

const qSet4 = [
  "Empathy and understanding towards students' personal challenges and providing appropriate support in a professional manner",
  "Positive attitude to continuous learning",
  "Assisting students to explore career options",
  "Assisting students to find postgraduate positions for higher studies",
];

const qSet5 = [
  "Capacity to organize curricular activities",
  "Readiness to Speak for students when necessary, and encourages them to make best use of institutional and professional opportunities.",
  "Ability to organize department level activities",
  "Clarity while communicating with the stake holders",
  "Professionally  deals with parents, guardians and students",
];

const Quiz = () => {
  const [nomination, setNomination] = useState(5);
  const [ratings, setRatings] = useState(Array(NUM_QUESTIONS).fill(0));
  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [level, setLevel] = useState("senior");
  const [year, setYear] = useState("3");
  const [returnState, setReturnState] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    const userInfo = localStorage.getItem("user");

    const result = JSON.parse(userInfo);

    axiosInstance
      .get("/check-deadline", {
        headers: {
          Authorization: `${token}`,
        },
        params: {
          role: "FACULTY",
        },
      })
      .then((response) => {
        if (response["data"] === true) {
          axiosInstance
            .get("/nominations", {
              headers: {
                Authorization: `${token}`,
              },
              params: {
                id: result.Id,
              },
            })
            .then((response) => {
              setNomination(5 - response.data[0].count);
            });
        } else {
          alert("Best Mentor Award nominations closed");
          localStorage.clear();
          setReturnState(false);
        }
      });
  });

  const reset = () => {
    window.location.reload();
    window.scrollTo(0, 0);
  };

  const handleRatingChange = (index, value) => {
    const newRatings = [...ratings];
    newRatings[index] = value;
    setRatings(newRatings);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const userInfo = localStorage.getItem("user");
    const result = JSON.parse(userInfo);

    let count = 0;
    for (let i = 0; i < ratings.length; i++) {
      if (level === "mid" && (i === 13 || i === 14) && ratings[i] !== 0)
        continue;
      if (ratings[i] !== 0) count++;
    }

    let flag = false;

    if (
      (level === "senior" && count === NUM_QUESTIONS) ||
      (level === "mid" && count === NUM_QUESTIONS - 2)
    )
      flag = true;

    if (!flag) {
      alert("Please fill all the fields");
    } else {
      const data = {
        nominator: result.Name,
        nominator_id: result.Id,
        department: result.Deptname,
        nominee: name,
        nominee_id: id,
        category: level,
        year: year,
        ratings: JSON.stringify(ratings),
      };

      const formData = new FormData();
      formData.append("data", JSON.stringify(data));

      const token = localStorage.getItem("token");
      axiosInstance
        .post("/vote", formData, {
          headers: {
            Authorization: `${token}`,
          },
        })
        .then((res) => {
          console.log(res);
          alert(
            "Your nomination has been submitted successfully. Thank you for your valuable time."
          );
          reset();
          navigate("/quiz");
        });
    }
  };

  const renderStarRating = (index) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      const starClass =
        i <= ratings[index] ? "star star-filled" : "star star-empty";
      stars.push(
        <span
          key={i}
          className={starClass}
          onClick={() => handleRatingChange(index, i)}
        ></span>
      );
    }
    return stars;
  };

  const getRatingLabel = (rating) => {
    switch (rating) {
      case 1:
        return "Poor";
      case 2:
        return "Satisfactory";
      case 3:
        return "Good";
      case 4:
        return "Very Good";
      case 5:
        return "Excellent";
      default:
        return "";
    }
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleIdChange = (e) => {
    setId(e.target.value);
  };

  const handleLevelChange = (e) => {
    setLevel(e.target.value);
  };

  const handleYearChange = (e) => {
    setYear(e.target.value);
  };

  if (!returnState) return <Login />;

  return (
    <>
      <NavigationBar />
      <div className="rating-page">
        <div className="pb-2">
          <h1 className="font-bold">Peer Nomination for Best Mentor Award</h1>
          <p className="pb-2">
            Number of nominations left: <b>{nomination}</b>
          </p>
        </div>

        {nomination > 0 ? (
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">
                Name of the nominee (from your department)
              </label>
              <input
                required
                type="text"
                id="name"
                value={name}
                onChange={handleNameChange}
                placeholder="Name"
              />
            </div>

            <div className="form-group">
              <label htmlFor="name">Nominee Employee ID</label>
              <input
                required
                type="text"
                id="name"
                value={id}
                onChange={handleIdChange}
                placeholder="Employee ID"
              />
            </div>

            <div className="form-group my-5">
              <label htmlFor="year">
                How long do you know the nominee you are nominating while
                working in the department of PSG College of Technology (in
                years)?
              </label>
              <select
                id="year"
                value={year}
                onChange={handleYearChange}
                placeholder="Select the year"
                className="border border-3 border-gray-300 rounded p-2 w-full md:w-auto"
              >
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                <option value="13">13</option>
                <option value="14">14</option>
                <option value="15">15</option>
                <option value="16">16</option>
                <option value="17">17</option>
                <option value="18">18</option>
                <option value="19">19</option>
                <option value="20">20</option>
                <option value="more than 20 years">More than 20 years</option>
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="level" className="text-gray-700">
                Category
              </label>
              <select
                id="level"
                value={level}
                onChange={handleLevelChange}
                placeholder="Select the level"
                className="border border-3 border-gray-300 rounded p-2 w-full md:w-auto"
              >
                <option value="senior">Senior level</option>
                <option value="mid">Mid level</option>
              </select>
            </div>

            <h1>Rate Your Nominee</h1>

            <p className="font-bold my-8">Self discipline and Pedagogy</p>
            {qSet1.map((question, index) => (
              <div key={index} className="question">
                <p>{question}</p>
                <div className="star-rating">{renderStarRating(index)}</div>
                <div className="selected-rating-label">
                  {getRatingLabel(ratings[index])}
                </div>
              </div>
            ))}

            <p className="font-bold my-8">Ability to be with a Team</p>
            {qSet2.map((question, index) => (
              <div key={index} className="question">
                <p>{question}</p>
                <div className="star-rating">
                  {renderStarRating(10 + index)}
                </div>
                <div className="selected-rating-label">
                  {getRatingLabel(ratings[10 + index])}
                </div>
              </div>
            ))}

            {level === "senior" &&
              specialQuestions.map((question, index) => (
                <div key={index} className="question">
                  <p>{question}</p>
                  <div className="star-rating">
                    {renderStarRating(13 + index)}
                  </div>
                  <div className="selected-rating-label">
                    {getRatingLabel(ratings[13 + index])}
                  </div>
                </div>
              ))}

            <p className="font-bold my-8">
              Student support for scholarly activities
            </p>
            {qSet3.map((question, index) => (
              <div key={index} className="question">
                <p>{question}</p>
                <div className="star-rating">
                  {renderStarRating(15 + index)}
                </div>
                <div className="selected-rating-label">
                  {getRatingLabel(ratings[15 + index])}
                </div>
              </div>
            ))}

            <p className="font-bold my-8">Students' counselling</p>
            {qSet4.map((question, index) => (
              <div key={index} className="question">
                <p>{question}</p>
                <div className="star-rating">
                  {renderStarRating(19 + index)}
                </div>
                <div className="selected-rating-label">
                  {getRatingLabel(ratings[19 + index])}
                </div>
              </div>
            ))}

            <p className="font-bold my-8">Leadership</p>
            {qSet5.map((question, index) => (
              <div key={index} className="question">
                <p>{question}</p>
                <div className="star-rating">
                  {renderStarRating(23 + index)}
                </div>
                <div className="selected-rating-label">
                  {getRatingLabel(ratings[23 + index])}
                </div>
              </div>
            ))}

            <div>
              <p>
                <input type="checkbox" required /> Based on the norms prescribed
                for this award, I nominate <b>{name}</b> of my department for
                the Best Mentor Award in the <b>{level}</b> category. The
                information provided is true to the best of my knowledge.
              </p>
            </div>

            <button
              type="submit"
              className="bg-blue-500 text-white hover:bg-blue-700 text-sm font-semibold py-2 px-4 rounded focus:outline-none"
            >
              Submit
            </button>
          </form>
        ) : (
          <p>
            You have already made <b>5</b> nominations.
          </p>
        )}
      </div>
    </>
  );
};

export default Quiz;
