import React, { useEffect } from "react";
import "./Quiz.css";
import { useLocation } from "react-router-dom";
import NavigationBar from "../components/Header/Navigationbar";
import NotFoundPage from "./NotFoundPage";

const questions = [
  "Support for NBA preparation (SAR files)",
  "Support for NAAC preparation",
  "Support for NIRF preparation",
  "Support for preparing Timetable",
  "Involvement in development of laboratories and making it available for students.",
  "Contribution to department association",
  "Smooth and cordial interaction with other colleagues in the department and in the campus",
];

const ViewRecommendation = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (location.state === undefined || location.state === null) {
    return <NotFoundPage />;
  } else {
    const { ratings } = location.state;

    const renderStarRating = (index) => {
      const stars = [];
      const rating = ratings[index];

      for (let i = 1; i <= 6; i++) {
        const starClass = i <= rating ? "star star-filled" : "star star-empty";
        stars.push(<span key={i} className={starClass}></span>);
      }

      return stars;
    };

    const getRatingLabel = (rating) => {
      switch (rating) {
        case 1:
          return "NA";
        case 2:
          return "Poor";
        case 3:
          return "Satisfactory";
        case 4:
          return "Good";
        case 5:
          return "Very Good";
        case 6:
          return "Excellent";
        default:
          return "";
      }
    };

    return (
      <>
        <NavigationBar />
        <div className="rating-page">
          <div>
            <h1 className="font-bold">Best Mentor Award Nomination</h1>
          </div>
          {questions.map((question, index) => (
            <div key={index} className="question">
              <p>{question}</p>
              <div className="star-rating">{renderStarRating(index)}</div>
              <div className="selected-rating-label">
                {getRatingLabel(ratings[index])}
              </div>
            </div>
          ))}
        </div>
      </>
    );
  }
};

export default ViewRecommendation;
