import React from "react";
import { axiosInstance } from "../../components/api/index.js";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [type, setType] = React.useState("Faculty");

  const navigate = useNavigate();

  function login(event) {
    event.preventDefault();

    const formData = new FormData();
    formData.append("username", username);
    formData.append("password", password);
    formData.append("type", type);

    axiosInstance
      .post("/login", formData)
      .then((response) => {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("user", JSON.stringify(response.data.user));

        const roleName = response.data.user.RoleName;
        if (roleName.includes("ADMIN")) {
          navigate("/admin-landing");
        } else if (roleName.includes("PRINCIPAL")) {
          navigate("/nomination-result");
        } else if (roleName.includes("HOD")) {
          navigate("/poll-result");
        } else {
          navigate("/quiz");
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 500) {
          alert("Internal Server Error. Please try again later.");
        } else {
          alert("Login failed. Please try again.");
        }
      });
  }

  return (
    <div
      className="flex flex-col lg:flex-row h-screen items-center justify-center"
      style={{ width: "80%", margin: "0 auto" }}
    >
      <div className="lg:w-1/2 bg-blue-500" style={{ width: "80%" }}>
        <div className="p-10">
          <h1 className="text-3xl font-bold text-white mb-6">
            Login
            <p className="font-light text-base">
              Use {type === "Faculty" ? "academic" : "admin"} login credentials
            </p>
          </h1>

          <form onSubmit={login}>
            <div className="grid w-[75%] grid-cols-2 gap-2 rounded-xl bg-white p-2 mb-5 mx-auto">
              <div>
                <input
                  type="radio"
                  name="type"
                  id="1"
                  value="Faculty"
                  className="peer hidden"
                  defaultChecked
                  onClick={() => setType("Faculty")}
                />
                <label
                  for="1"
                  className="block mb-0 cursor-pointer select-none rounded-xl p-2 text-center peer-checked:bg-blue-500 peer-checked:font-bold peer-checked:text-white"
                >
                  Faculty
                </label>
              </div>

              <div>
                <input
                  type="radio"
                  name="type"
                  id="2"
                  value="Admin"
                  class="peer hidden"
                  onClick={() => setType("Admin")}
                />
                <label
                  for="2"
                  class="block mb-0 cursor-pointer select-none rounded-xl p-2 text-center peer-checked:bg-blue-500 peer-checked:font-bold peer-checked:text-white"
                >
                  Admin
                </label>
              </div>
            </div>

            <div className="mb-4">
              <label
                className="block text-white text-sm font-medium mb-2"
                htmlFor="username"
              >
                {type === "Faculty" ? "Username" : "Email"}
              </label>
              <input
                className="w-full px-3 py-2 rounded bg-white text-gray-700 focus:outline-none"
                type="text"
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-white text-sm font-medium mb-2"
                htmlFor="password"
              >
                Password
              </label>
              <input
                className="w-full px-3 py-2 rounded bg-white text-gray-700 focus:outline-none"
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <button
              type="submit"
              className="hover:bg-blue-700 hover:text-white bg-white text-blue-500 text-sm font-semibold py-2 px-4 rounded focus:outline-none"
            >
              Sign In
            </button>
          </form>
        </div>
      </div>
      <div className="lg:w-1/2">
        <div className="flex items-center justify-center p-10">
          <img src={require("../auth/logo.png")} alt="psg" className="w-2/3" />
        </div>
      </div>
    </div>
  );
};

export default Login;
