import React from "react";
import { useState, useEffect } from "react";
import { axiosInstance } from "../components/api/index.js";
import NavigationBar from "../components/Header/Navigationbar";

export default function AdminDeadlineSelection() {
  const [currentYear, setCurrentYear] = useState(0);
  const [facultyDeadline, setFacultyDeadline] = useState("");
  const [HODDeadline, setHODDeadline] = useState("");

  const [newFacultyDeadline, setNewFacultyDeadline] = useState("");
  const [newHODDeadline, setNewHODDeadline] = useState("");

  const [SubmitEnabled, setSubmitEnabled] = useState(false);
  const [editSelected, setEditSelected] = useState(false);

  function formatDate(dateString) {
    const parts = dateString.split(", ")[0].split("/");
    const year = parts[2];
    const month = String(parts[0]).padStart(2, "0");
    const day = String(parts[1]).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  useEffect(() => {
    const token = localStorage.getItem("token");
    axiosInstance
      .get("/deadline", {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        const year = response.data.Year;
        const options = {
          weekday: "short",
          month: "short",
          day: "numeric",
          year: "numeric",
        };
        const facultyDeadline = new Date(
          response.data.FacultySubmissionDeadline
        ).toLocaleDateString("en-us", options);
        const hodDeadline = new Date(
          response.data.HODSubmissionDeadline
        ).toLocaleDateString("en-us", options);

        setCurrentYear(year);
        setFacultyDeadline(facultyDeadline);
        setHODDeadline(hodDeadline);
        setNewFacultyDeadline(
          formatDate(
            new Date(
              response.data.FacultySubmissionDeadline
            ).toLocaleDateString()
          )
        );
        setNewHODDeadline(
          formatDate(
            new Date(response.data.HODSubmissionDeadline).toLocaleDateString()
          )
        );

        setHODDeadline(hodDeadline);
      });
  }, []);

  const handleSubmit = (e) => {
    // e.preventDefault();
    const formData = new FormData();
    console.log(newFacultyDeadline, newHODDeadline);
    if (newFacultyDeadline === "" && newHODDeadline === "") {
      alert("Please enter a valid date for atleast one deadline to update");
      return;
    }

    formData.append("facultyDeadline", newFacultyDeadline);
    formData.append("HODDeadline", newHODDeadline);
    formData.append("year", currentYear);

    const token = localStorage.getItem("token");
    axiosInstance.post("/update-deadline", formData, {
      headers: {
        Authorization: `${token}`,
      },
    }).then((response) => {
      console.log(response.data);
    });
  };

  return (
    <>
      <NavigationBar />
      <h1 className="font-bold mt-10">View/Set Deadlines</h1>
      <div className=" flex items-center justify-center">
        <div className="bg-gray-200 rounded-lg p-8 shadow-md">
          <p className="text-xl mb-5 font-bold text-center">
            Year {currentYear}
          </p>

          <form className="w-full">
            <table className="w-full">
              <thead>
                <tr>
                  <th className="px-4 py-2 text-left">Task</th>
                  <th className="px-4 py-2 text-left">Date</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="px-4 py-2">
                    <label htmlFor="facultyDeadline" className="block mb-2">
                      Faculty Submissions Deadline
                    </label>
                  </td>
                  <td className="px-4 py-2">
                    <input
                      type={editSelected ? "date" : "text"}
                      id="facultyDeadline"
                      name="facultyDeadline"
                      value={
                        editSelected ? newFacultyDeadline : facultyDeadline
                      }
                      onChange={(e) => {
                        setNewFacultyDeadline(e.target.value);
                        console.log(newFacultyDeadline);
                      }}
                      className="w-full bg-white border border-gray-300 rounded-md py-2 px-4"
                      disabled={!editSelected}
                    />
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td className="px-4 py-2">
                    <label htmlFor="facultyDeadline" className="block mb-2">
                      HOD Submissions Deadline
                    </label>
                  </td>
                  <td className="px-4 py-2">
                    <input
                      type={editSelected ? "date" : "text"}
                      id="HODDeadline"
                      name="HOdDeadline"
                      value={editSelected ? newHODDeadline : HODDeadline}
                      onChange={(e) => setNewHODDeadline(e.target.value)}
                      className="w-full bg-white border border-gray-300 rounded-md py-2 px-4"
                      disabled={!editSelected}
                    />
                  </td>
                  <td className="px-4 py-2"></td>
                </tr>
              </tbody>
            </table>
            <div className="flex items-center justify-between mt-4">
              <button
                type="button"
                className="bg-white text-blue-500 rounded-md"
                onClick={() => {
                  setSubmitEnabled(!SubmitEnabled);
                  setEditSelected(!editSelected);
                }}
              >
                {editSelected ? "Back" : "Edit"}
              </button>
              <button
                type="submit"
                className="bg-white text-black font-semibold py-2 px-4 rounded-md hover:bg-blue-500"
                onClick={handleSubmit}
                disabled={!SubmitEnabled}
              >
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
