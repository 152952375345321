import React from "react";
import { Link } from "react-router-dom";
import NavigationBar from "../components/Header/Navigationbar";
import { axiosInstance } from "../components/api/index.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faUsers,
  faUserTie,
  faUserShield,
} from "@fortawesome/free-solid-svg-icons";

import { useState, useEffect } from "react";

function Card({ icon, title, linkTo }) {
  return (
    <Link
      to={linkTo}
      className="card-link mx-auto my-6 w-full sm:w-[45%] md:w-[30%] lg:w-[20%]"
    >
      <div className="card flex flex-col items-center">
        <FontAwesomeIcon
          icon={icon}
          className="icon text-5xl sm:text-6xl lg:text-8xl w-14 sm:w-16 lg:w-20 h-14 sm:h-16 lg:h-20"
          color="gray"
        />
        <div className="card-content mt-4 px-4 text-center">
          <h3 className="text-lg sm:text-xl lg:text-lg">{title}</h3>
        </div>
      </div>
    </Link>
  );
}

const AdminLanding = () => {
  const [currentYear, setCurrentYear] = useState(0);

  const proceedToNextYear = () => {
    const userchoice = window.confirm(
      "Are you sure you want to proceed to the next year? (This will clear data for the current year)"
    );
    if (userchoice) {
      const formData = new FormData();
      formData.append("year", currentYear + 1);
      const token = localStorage.getItem("token");
      axiosInstance
        .post("/prepare-next-year", formData, {
          headers: {
            Authorization: `${token}`,
          },
        })
        .then((response) => {
          window.location.reload();
        });
    } else {
      return;
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    axiosInstance
      .get("/deadline", {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((response) => {
        setCurrentYear(response.data.Year);
      });
  }, []);
  return (
    <>
      <NavigationBar />
      <div className="py-4">
        <h2 className="font-bold text-center">Admin Settings</h2>
      </div>

      <div className="card-container flex flex-wrap justify-center">
        <Card icon={faUserShield} title="Add Admin" linkTo="/add-admin" />
        <Card
          icon={faCalendar}
          title="View/Set Deadlines"
          linkTo="/deadlines"
        />
        <Card
          icon={faUsers}
          title="Faculty Submissions"
          linkTo="/faculty-submissions"
        />
        <Card
          icon={faUserTie}
          title="HOD Submissions"
          linkTo="/hod-submissions"
        />
      </div>

      <div className="flex flex-wrap justify-between bg-gray-50 py-5 px-20 m-10 rounded-lg items-center border border-gray-200">
        <div className="text-gray-500 text-lg">
          <p>
            <i>Are the nominations for year {currentYear} over?</i>
          </p>
        </div>
        <div>
          <button
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-4 px-6 rounded-lg mt-0"
            onClick={proceedToNextYear}
          >
            Proceed to {currentYear + 1}
          </button>
        </div>
      </div>
    </>
  );
};

export default AdminLanding;
