import React from "react";
import "./Card.css";

const Card = ({ title, children }) => {
  return (
    <div className="card mt-8">
      {title && <h2 className="card-title">{title}</h2>}
      <div className="card-content">{children}</div>
    </div>
  );
};

export default Card;