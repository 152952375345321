import React, { useState, useEffect } from "react";
import NavigationBar from "../components/Header/Navigationbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { axiosInstance, BASE_URL } from "../components/api";

const HODSubmissions = () => {
  const [seniorCategory, setSeniorCategory] = useState([]);
  const [midCategory, setMidCategory] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("token");

    axiosInstance
      .get("/all-nominees", {
        headers: {
          Authorization: `${token}`,
        },
        params: { category: "senior" },
      })
      .then((response) => {
        setSeniorCategory(response.data);
      })
      .catch((error) => {
        console.error("Error fetching HOD submissions:", error);
      });

    axiosInstance
      .get("/all-nominees", {
        headers: {
          Authorization: `${token}`,
        },
        params: { category: "mid" },
      })
      .then((response) => {
        setMidCategory(response.data);
      })
      .catch((error) => {
        console.error("Error fetching HOD submissions:", error);
      });
  }, []);

  const generatePDF = async () => {
    try {
      const token = localStorage.getItem("token");
      const headers = new Headers();
      headers.append("Authorization", `${token}`);

      const response = await fetch(BASE_URL + "/api/final-pdf", {
        headers: headers,
      });

      if (!response.ok) {
        throw new Error("Failed to fetch PDF");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "BMA_report.pdf");
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error downloading PDF:", error);
      alert("Error downloading PDF:", error);
    }
  };

  return (
    <div>
      <NavigationBar />
      <div className="flex justify-between items-center">
        <h1 className="text-center font-bold my-4 flex-grow">
          HOD Submissions
        </h1>
        <FontAwesomeIcon
          icon={faDownload}
          className="text-xl cursor-pointer text-gray-500 mr-9"
          onClick={generatePDF}
        />
      </div>

      <p className="text-center my-2 font-bold">
        Senior category: {seniorCategory.length}
      </p>
      <div className="overflow-x-auto mx-auto max-w-screen-xl">
        <table className="w-full table-auto text-center">
          <thead>
            <tr className="bg-gray-200">
              <th className="px-4 py-2">SNo</th>
              <th className="px-4 py-2 border">Nominee</th>
              <th className="px-4 py-2 border">Nominee ID</th>
              <th className="px-4 py-2 border">Department</th>
              <th className="px-4 py-2 border">Nominator</th>
              <th className="px-4 py-2 border">Total ratings</th>
            </tr>
          </thead>
          <tbody>
            {seniorCategory.map((submission) => (
              <tr key={submission.id}>
                <td className="border px-4 py-2 text-sm">{submission.SNo}</td>
                <td className="border px-4 py-2 text-sm">
                  {submission.nominee}
                </td>
                <td className="border px-4 py-2 text-sm">
                  {submission.nominee_id}
                </td>
                <td className="border px-4 py-2 text-sm">
                  {submission.department}
                </td>
                <td className="border px-4 py-2 text-sm">
                  {submission.nominator}
                </td>
                <td className="border px-4 py-2 text-sm">
                  {submission.ratingsSum}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <p className="text-center my-6 font-bold">
        Mid category: {midCategory.length}
      </p>
      <div className="overflow-x-auto mx-auto max-w-screen-xl">
        <table className="w-full table-auto text-center">
          <thead>
            <tr className="bg-gray-200">
              <th className="px-4 py-2">SNo</th>
              <th className="px-4 py-2 border">Nominee</th>
              <th className="px-4 py-2 border">Nominee ID</th>
              <th className="px-4 py-2 border">Department</th>
              <th className="px-4 py-2 border">Nominator</th>
              <th className="px-4 py-2 border">Total ratings</th>
            </tr>
          </thead>
          <tbody>
            {midCategory.map((submission) => (
              <tr key={submission.id}>
                <td className="border px-4 py-2 text-sm">{submission.SNo}</td>
                <td className="border px-4 py-2 text-sm">
                  {submission.nominee}
                </td>
                <td className="border px-4 py-2 text-sm">
                  {submission.nominee_id}
                </td>
                <td className="border px-4 py-2 text-sm">
                  {submission.department}
                </td>
                <td className="border px-4 py-2 text-sm">
                  {submission.nominator}
                </td>
                <td className="border px-4 py-2 text-sm">
                  {submission.ratingsSum}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default HODSubmissions;
