import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

import NavigationBar from "../components/Header/Navigationbar";
import { axiosInstance } from "../components/api";

const FacultySubmissions = () => {
  const [seniorCategory, setSeniorCategory] = useState([]);
  const [midCategory, setMidCategory] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("token");

    axiosInstance
      .get("/all-ratings", {
        headers: {
          Authorization: `${token}`,
        },
        params: { category: "senior" },
      })
      .then((response) => {
        setSeniorCategory(response.data);
      })
      .catch((error) => {
        console.error("Error fetching faculty submissions:", error);
      });

    axiosInstance
      .get("/all-ratings", {
        headers: {
          Authorization: `${token}`,
        },
        params: { category: "mid" },
      })
      .then((response) => {
        setMidCategory(response.data);
      })
      .catch((error) => {
        console.error("Error fetching faculty submissions:", error);
      });
  }, []);

  const generatePDF = () => {
    const doc = new jsPDF();

    doc.setFontSize(8);
    doc.setTextColor(0, 0, 0);
    doc.text(
      "PSG COLLEGE OF TECHNOLOGY - BMA SENIOR NOMINATIONS",
      doc.internal.pageSize.getWidth() / 2,
      10,
      { align: "center" }
    );

    doc.autoTable({
      startY: 20,
      head: [
        [
          {
            content: "SNo",
            styles: {
              fontStyle: "bold",
              fillColor: [218, 218, 218],
              textColor: [0, 0, 0],
              fontSize: 6,
            },
          },
          {
            content: "Nominee",
            styles: {
              fontStyle: "bold",
              fillColor: [218, 218, 218],
              textColor: [0, 0, 0],
              fontSize: 6,
            },
          },
          {
            content: "Nominee ID",
            styles: {
              fontStyle: "bold",
              fillColor: [218, 218, 218],
              textColor: [0, 0, 0],
              fontSize: 6,
            },
          },
          {
            content: "Department",
            styles: {
              fontStyle: "bold",
              fillColor: [218, 218, 218],
              textColor: [0, 0, 0],
              fontSize: 6,
            },
          },
          {
            content: "Nominator ID",
            styles: {
              fontStyle: "bold",
              fillColor: [218, 218, 218],
              textColor: [0, 0, 0],
              fontSize: 6,
            },
          },
          {
            content: "Nominator",
            styles: {
              fontStyle: "bold",
              fillColor: [218, 218, 218],
              textColor: [0, 0, 0],
              fontSize: 6,
            },
          },
          {
            content: "Years",
            styles: {
              fontStyle: "bold",
              fillColor: [218, 218, 218],
              textColor: [0, 0, 0],
              fontSize: 6,
            },
          },
          {
            content: "Total ratings",
            styles: {
              fontStyle: "bold",
              fillColor: [218, 218, 218],
              textColor: [0, 0, 0],
              fontSize: 6,
            },
          },
        ],
      ],
      body: seniorCategory.map((submission) => [
        submission.SNo,
        submission.nominee,
        submission.nominee_id,
        submission.department,
        submission.nominator_id,
        submission.nominator,
        submission.years,
        submission.ratingsSum,
      ]),
      theme: "grid",
      styles: {
        fontSize: 6,
        textColor: [0, 0, 0],
        fontStyle: "normal",
        cellPadding: 2,
        valign: "middle",
        halign: "center",
      },
    });

    doc.addPage();

    doc.setFontSize(8);
    doc.setTextColor(0, 0, 0);
    doc.text(
      "PSG COLLEGE OF TECHNOLOGY - BMA MID NOMINATIONS",
      doc.internal.pageSize.getWidth() / 2,
      10,
      { align: "center" }
    );

    doc.autoTable({
      startY: 20,
      head: [
        [
          {
            content: "SNo",
            styles: {
              fontStyle: "bold",
              fillColor: [218, 218, 218],
              textColor: [0, 0, 0],
              fontSize: 6,
            },
          },
          {
            content: "Nominee",
            styles: {
              fontStyle: "bold",
              fillColor: [218, 218, 218],
              textColor: [0, 0, 0],
              fontSize: 6,
            },
          },
          {
            content: "Nominee ID",
            styles: {
              fontStyle: "bold",
              fillColor: [218, 218, 218],
              textColor: [0, 0, 0],
              fontSize: 6,
            },
          },
          {
            content: "Department",
            styles: {
              fontStyle: "bold",
              fillColor: [218, 218, 218],
              textColor: [0, 0, 0],
              fontSize: 6,
            },
          },
          {
            content: "Nominator ID",
            styles: {
              fontStyle: "bold",
              fillColor: [218, 218, 218],
              textColor: [0, 0, 0],
              fontSize: 6,
            },
          },
          {
            content: "Nominator",
            styles: {
              fontStyle: "bold",
              fillColor: [218, 218, 218],
              textColor: [0, 0, 0],
              fontSize: 6,
            },
          },
          {
            content: "Years",
            styles: {
              fontStyle: "bold",
              fillColor: [218, 218, 218],
              textColor: [0, 0, 0],
              fontSize: 6,
            },
          },
          {
            content: "Total ratings",
            styles: {
              fontStyle: "bold",
              fillColor: [218, 218, 218],
              textColor: [0, 0, 0],
              fontSize: 6,
            },
          },
        ],
      ],
      body: midCategory.map((submission) => [
        submission.SNo,
        submission.nominee,
        submission.nominee_id,
        submission.department,
        submission.nominator_id,
        submission.nominator,
        submission.years,
        submission.ratingsSum,
      ]),
      theme: "grid",
      styles: {
        fontSize: 6,
        textColor: [0, 0, 0],
        fontStyle: "normal",
        cellPadding: 2,
        valign: "middle",
        halign: "center",
      },
    });

    doc.save("BMA_nominations.pdf");
  };

  return (
    <>
      <NavigationBar />
      <div>
        <div className="flex justify-between items-center">
          <h1 className="text-center font-bold my-4 flex-grow">
            Faculty Submissions
          </h1>
          <FontAwesomeIcon
            icon={faDownload}
            className="text-xl cursor-pointer text-gray-500 mr-9"
            onClick={generatePDF}
          />
        </div>

        <p className="text-center my-2 font-bold">
          Senior category: {seniorCategory.length}
        </p>
        <div className="overflow-x-auto mx-auto max-w-screen-xl">
          <table className="w-full table-auto text-center">
            <thead>
              <tr className="bg-gray-200">
                <th className="px-4 py-2">SNo</th>
                <th className="px-4 py-2 border">Nominee</th>
                <th className="px-4 py-2 border">Nominee ID</th>
                <th className="px-4 py-2 border">Department</th>
                <th className="px-4 py-2 border">Nominator ID</th>
                <th className="px-4 py-2 border">Nominator</th>
                <th className="px-4 py-2 border">Years</th>
                <th className="px-4 py-2 border">Total ratings</th>
              </tr>
            </thead>
            <tbody>
              {seniorCategory.map((submission) => (
                <tr key={submission.id}>
                  <td className="border px-4 py-2 text-sm">{submission.SNo}</td>
                  <td className="border px-4 py-2 text-sm">
                    {submission.nominee}
                  </td>
                  <td className="border px-4 py-2 text-sm">
                    {submission.nominee_id}
                  </td>
                  <td className="border px-4 py-2 text-sm">
                    {submission.department}
                  </td>
                  <td className="border px-4 py-2 text-sm">
                    {submission.nominator_id}
                  </td>
                  <td className="border px-4 py-2 text-sm">
                    {submission.nominator}
                  </td>
                  <td className="border px-4 py-2 text-sm">
                    {submission.years}
                  </td>
                  <td className="border px-4 py-2 text-sm">
                    {submission.ratingsSum}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <p className="text-center my-6 font-bold">
          Mid category: {midCategory.length}
        </p>
        <div className="overflow-x-auto mx-auto max-w-screen-xl">
          <table className="w-full table-auto text-center">
            <thead>
              <tr className="bg-gray-200">
                <th className="px-4 py-2">SNo</th>
                <th className="px-4 py-2 border">Nominee</th>
                <th className="px-4 py-2 border">Nominee ID</th>
                <th className="px-4 py-2 border">Department</th>
                <th className="px-4 py-2 border">Nominator ID</th>
                <th className="px-4 py-2 border">Nominator</th>
                <th className="px-4 py-2 border">Years</th>
                <th className="px-4 py-2 border">Total ratings</th>
              </tr>
            </thead>
            <tbody>
              {midCategory.map((submission) => (
                <tr key={submission.id}>
                  <td className="border px-4 py-2 text-sm">{submission.SNo}</td>
                  <td className="border px-4 py-2 text-sm">
                    {submission.nominee}
                  </td>
                  <td className="border px-4 py-2 text-sm">
                    {submission.nominee_id}
                  </td>
                  <td className="border px-4 py-2 text-sm">
                    {submission.department}
                  </td>
                  <td className="border px-4 py-2 text-sm">
                    {submission.nominator_id}
                  </td>
                  <td className="border px-4 py-2 text-sm">
                    {submission.nominator}
                  </td>
                  <td className="border px-4 py-2 text-sm">
                    {submission.years}
                  </td>
                  <td className="border px-4 py-2 text-sm">
                    {submission.ratingsSum}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default FacultySubmissions;
