import React, { useState } from "react";
import { axiosInstance } from "../components/api/index.js";
import NavigationBar from "../components/Header/Navigationbar";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AddAdmin = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!username || !password) {
      alert("Please enter both username and password");
      return;
    }

    const token = localStorage.getItem("token");
    axiosInstance
      .post("/signup", {
        headers: {
          Authorization: `${token}`,
        },
        params: {
          email: username,
          password: password,
        },
      })
      .then((response) => {
        console.log(response.data);

        alert("Admin added successfully!");
        setUsername("");
        setPassword("");
      })
      .catch((error) => {
        console.error("Error adding admin:", error);
        alert("Failed to add admin. Please try again later.");
      });
  };

  return (
    <>
      <NavigationBar />
      <div className="flex items-center justify-center mt-10">
        <div className="bg-gray-200 rounded-lg p-8 shadow-md w-96">
          <h1 className="text-xl font-bold mb-4 text-center">Add Admin</h1>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label htmlFor="username" className="block mb-2">
                Username
              </label>
              <input
                type="text"
                id="username"
                name="username"
                value={username}
                onChange={handleUsernameChange}
                className="w-full bg-white border border-gray-300 rounded-md py-2 px-4"
                placeholder="Enter username"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="password" className="block mb-2">
                Password
              </label>
              <input
                type="password"
                id="password"
                name="password"
                value={password}
                onChange={handlePasswordChange}
                className="w-full bg-white border border-gray-300 rounded-md py-2 px-4"
                placeholder="Enter password"
              />
            </div>
            <button
              type="submit"
              className="bg-blue-500 text-white font-semibold py-2 px-4 rounded-md w-full hover:bg-blue-600"
            >
              <FontAwesomeIcon icon={faUserPlus} className="mr-2" />
              Add Admin
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddAdmin;
